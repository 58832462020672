import * as PropTypes from "prop-types"
import React from "react"

import { QuestionAnswer } from "../components/index";

class FaqPageTemplate extends React.PureComponent {
  createMarkup(html) {
    return {__html: html};
  };
  render() {
    const {
      pageContext: {
        faq,
        type
      }
    } = this.props;
    return (
      <QuestionAnswer {...faq} type={type}>
        <p dangerouslySetInnerHTML={this.createMarkup(faq.answer)} />
      </QuestionAnswer>
    )
  }
}

FaqPageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    faq: PropTypes.shape({
      route: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      formattedDate: PropTypes.string.isRequired,
    }),
  }).isRequired
}

export default FaqPageTemplate